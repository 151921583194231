import './App.css';
import AboutMe from './components/AboutMe';
import Home from './components/Home';
import Kontakt from './components/Kontakt';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Skills from './components/Skills';

function App() {
  return (
    <div>
      Offline.
    </div>
    /* <div className="App">
      <Navbar></Navbar>
      <Home></Home>
      <AboutMe></AboutMe>
      <Skills></Skills>
      <Projects></Projects>
      <Kontakt></Kontakt>
    </div> */
  );
}

export default App;
